import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  BillingRate,
  BillingRateStatus,
  SessionBillingApi,
} from "@practice/sdk";

import useHasFeature from "@hooks/use-has-feature";
import useLogger from "@hooks/use-logger";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";
import { FeatureNames } from "@lib/constants/featureNames";
import { catchErrorSDK } from "@lib/utils/catch-error-client";

import { useAuth } from "./auth";

type BillingRatesContextType = {
  isFetching: boolean;
  billingRates: BillingRate[];
  activeBillingRates: BillingRate[];
  setBillingRates: (billingRates: BillingRate[]) => void;
  hasSessionBillingSettings: boolean;
  currency?: string;
  error?: string | null;
  canAccessTheFeature: boolean;
  billingSettingsTimeZone: string;
};

const BillingRatesContext = createContext<BillingRatesContextType>({
  isFetching: false,
  billingRates: [],
  activeBillingRates: [],
  setBillingRates: () => {},
  hasSessionBillingSettings: false,
  currency: undefined,
  error: null,
  canAccessTheFeature: false,
  billingSettingsTimeZone: "American/Los_Angeles",
});

export const useBillingRatesContext = () => useContext(BillingRatesContext);

interface BillingRatesProviderProps {
  children: ReactNode;
}
export const BillingRatesProvider: FC<BillingRatesProviderProps> = ({
  children,
}) => {
  const { organization, aid } = useAuth();
  const [hasBusinessFeature] = useHasFeature(
    organization?.id,
    FeatureNames.accountsPayable
  );
  const { logger } = useLogger("BillingRateProvider");
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [rateTypes, setRateTypes] = useState<BillingRate[]>([]);
  const generateRequestId = useRequestIdGenerator("billing-rates-context");
  const sessionBillingApi = useSDKApi(SessionBillingApi);
  const sessionBillingSettings = organization?.sessionBillingSettings;
  const currency = sessionBillingSettings?.currency ?? "";
  const hasSessionBillingSettings = !!sessionBillingSettings;
  const billingSettingsTimeZone = sessionBillingSettings?.timeZone ?? "";

  const setBillingData = async () => {
    if (hasSessionBillingSettings) {
      setIsFetching(true);
      try {
        const result = await sessionBillingApi.getSessionBilling({
          organizationId: organization!.id,
          xRequestId: generateRequestId(),
        });

        const { billingRates } = result;

        if (billingRates) {
          setRateTypes(billingRates);
        }
      } catch (error: any) {
        const errorMessage = await catchErrorSDK(
          error,
          "Error loading billing rates"
        );
        setError(errorMessage);

        if (error?.response?.status !== 401) {
          logger.error({ error, errorMessage }, "Error loading billing rates");
        } else {
          logger.info(
            {
              accountId: aid,
              organizationId: organization?.id,
              errorMessage,
            },
            "User is not authorized to view billing rates"
          );
        }
      }
    }

    setIsFetching(false);
  };

  useEffect(() => {
    if (
      !isFetching &&
      hasBusinessFeature &&
      !error &&
      hasSessionBillingSettings &&
      !rateTypes?.length
    ) {
      setBillingData();
    }
  }, [
    hasSessionBillingSettings,
    isFetching,
    rateTypes,
    error,
    hasBusinessFeature,
  ]);

  return (
    <BillingRatesContext.Provider
      value={{
        isFetching,
        billingRates: rateTypes,
        activeBillingRates: rateTypes.filter(
          (rate) => rate.status === BillingRateStatus.Active
        ),
        setBillingRates: setRateTypes,
        hasSessionBillingSettings,
        currency,
        error,
        canAccessTheFeature: !!hasBusinessFeature,
        billingSettingsTimeZone,
      }}
    >
      {children}
    </BillingRatesContext.Provider>
  );
};
